import Dashboard from "components/screens/dashboard";
import ComplianceDashboard from "components/screens/dashboard/compliance-dashboard";
import TeamDashboard from "components/screens/team-dashboard";
import TeamDashboardCompliance from "components/screens/team-dashboard-compliance";
import CoachDashboard from "components/screens/dashboard/sleep-coach-dashboard";
import SleepCoachScoreCard from "components/screens/users/sleep-coach-score-card";
import Insights from "components/screens/insights";
import PhoneDashboard from "components/screens/phone-dashboard";
import FirstRegisterPage from "components/screens/first-register"; 
import EmployeeOnlineTimes from "components/employee-online-times";
import compliancePatientOutreach from "components/screens/dashboard/compliance-patient-outreach";
import FirstTimeRegistrationDashboard from "components/screens/first-time-registration-dashboard";

const InsightRoutes = [
  {
    path: "/first-time-registration-dashboard",
    component: FirstTimeRegistrationDashboard,
    allowedRoles: ["Administrator", "ServiceAdmin"]
  },
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/compliance-dashboard",
    component: ComplianceDashboard
  },
  {
    path: "/outreach",
    component: CoachDashboard,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator", "OfficeAdministrator", "CompanyAdministrator"]
  },
  {
    path: "/compliance-outreach",
    component: compliancePatientOutreach,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator", "OfficeAdministrator", "CompanyAdministrator"]
  },
  {
    path: "/resupply-team-dashboard",
    component: TeamDashboard,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  },
  {
    path: "/compliance-team-dashboard",
    component: TeamDashboardCompliance,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator", "CompanyAdministrator", "OfficeAdministrator"]
  },
  {
    path: "/score-card",
    component: SleepCoachScoreCard,
    allowedRoles: ["Administrator", "SleepCoach", "ServiceCoach", "ContractorAdministrator"]
  },
  {
    path: "/insights",
    component: Insights,
    allowedRoles: [
      "Administrator",
      "ServiceAdmin",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/phone-insights",
    component: PhoneDashboard,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/first-time-patients-matches/:ticketNumberId",
    component: FirstRegisterPage,
    allowedRoles: ["Administrator", "ServiceAdmin"]
  },
  {
    path: "/employees",
    component: EmployeeOnlineTimes,
    allowedRoles: ["Administrator", "ServiceAdmin", "ContractorAdministrator"]
  }
];

export default InsightRoutes;
