import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "./context";
import Header from "components/order-profile/header";
import PhysicianInformation from "./physician-information";
import InsuranceInformation from "./insurance-information";

const OrderAnswers = () => {
  const { order } = React.useContext(OrderProfileContext);
  const { questions } = order;

  const usageQuestions = questions?.filter(x => x.category == "Usage");
  const additionalInfoQuestions = questions?.filter(
    x => x.category == "Additional Info"
  );
  const dysfunctionQuestionss = questions?.filter(x =>
    x.category.includes("Dysfunctions")
  );
  const physicianQuestions = questions?.filter(x => x.category == "Physician");
  const insuranceQuestions = questions?.filter(x => x.category == "Insurance");
  const addressQuestions = questions?.filter(x => x.category == "Address");

  const physicianChangeQuestion = physicianQuestions?.find(
    x => x.id == "physician_change"
  );
  const addressChangeQuesiton = addressQuestions?.find(
    x => x.id == "address_change"
  );
  const insuranceChangeQuestion = insuranceQuestions?.find(
    x => x.id == "insurance_change"
  );
  return (
    <div>
      <div>
        <Header>Physician</Header>
        <div className="s3-ui-rows">
          {!physicianChangeQuestion && (
            <>
              {order.changed_physician ? (
                <div>Updated Physician information.</div>
              ) : (
                <div>No updates made to physician</div>
              )}
              <PhysicianInformation physician={order.patient_physician} />
            </>
          )}
          {physicianQuestions?.map(x => (
            <>
              <OrderAnswerRow key={x.id} {...x} />
              {x.id == "physician_change" && (
                <PhysicianInformation physician={order.patient_physician} />
              )}
            </>
          ))}
        </div>
      </div>
      {insuranceQuestions?.length > 0 && (
        <div>
          <Header>Insurance</Header>
          <div className="s3-ui-rows">
            {!insuranceChangeQuestion && order.changed_insurance && (
              <>
                <div>
                  I have changed insurance companies since my last order. My new
                  insurance is
                </div>
                <InsuranceInformation />
              </>
            )}
            {insuranceQuestions.map(x => (
              <>
                <OrderAnswerRow key={x.id} {...x} />
                {x.id == "insurance_change" && x.answer == "true" && (
                  <InsuranceInformation />
                )}
              </>
            ))}
          </div>
        </div>
      )}
      {usageQuestions?.length > 0 && (
        <div>
          <Header>Usage</Header>
          <div className="s3-ui-rows">
            {usageQuestions.map(x => (
              <OrderAnswerRow key={x.id} {...x} />
            ))}
          </div>
        </div>
      )}
      {dysfunctionQuestionss?.length > 0 && (
        <div>
          <Header>Dysfunctions</Header>
          <div className="s3-ui-rows">
            {dysfunctionQuestionss.map(x => (
              <OrderAnswerRow key={x.id} {...x} />
            ))}
          </div>
        </div>
      )}
      {additionalInfoQuestions?.length > 0 && (
        <div>
          <Header>Additional Information</Header>
          <div className="s3-ui-rows">
            {additionalInfoQuestions.map(x => (
              <OrderAnswerRow key={x.id} {...x} />
            ))}
          </div>
        </div>
      )}
      {!addressChangeQuesiton && order.changed_address && (
        <>
          <div>
            My shipp address has
            {order.address_change_type == "T" ? "temporarily" : "permanently"}
            changed since my last order
          </div>
        </>
      )}
      {addressQuestions?.length > 0 && (
        <div>
          {/* <Header>Address</Header> */}
          <div className="s3-ui-rows">
            {addressQuestions.map(x => (
              <>
                <OrderAnswerRow key={x.id} {...x} />
                {/*x.id == "address_change" && x.value && (
                  <div>address change</div>
                )*/}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderAnswers;

const OrderAnswerRow = ({ text, answer, type }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 100px",
        paddingLeft: 30,
        paddingRight: 100,
        fontSize: 14,
        gridGap: 10
      }}
    >
      <div
        style={{
          display: "flex"
        }}
      >
        {text}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#d9d9d9",
          textAlign: "center",
          borderRadius: "0.4em"
        }}
      >
        <OrderAnswer answer={answer} type={type} />
      </div>
    </div>
  );
};

OrderAnswerRow.propTypes = {
  text: PropTypes.string.isRequired,
  answer: PropTypes.string,
  type: PropTypes.string
};

const OrderAnswer = ({ answer, type }) => {
  switch (type) {
    case "Yes/No":
      if (answer == "true") return "Yes";
      else if (answer == "false") return "No";
      else return answer;
    default:
      return answer;
  }
};

OrderAnswer.propTypes = {
  answer: PropTypes.string,
  type: PropTypes.string
};
